<template>
  <div class="training-add-course">
    <table-list
      :loading="loading"
      :data="dataList"
      :columns="columns(this)"
      :btns="btns(this)"
      :operates="operates(this)"
      :options="{ operatesWidth: 170 }"
    ></table-list>
    <!-- <AddCourseShop ref="addCourseShop" :class-cert-id="classCertId" @success="addSuccess" /> -->
    <!-- <AddCoursePackage ref="addCoursePackage" @success="addSuccess" /> -->
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  {
    label: '从自建课程选择',
    type: 'primary',
    method: _this.handleAddFromPacket,
    width: 140,
  },
  // {
  //   label: '删除',
  //   type: 'danger',
  //   method: _this.handleDelete,
  // },
]
// 表格列配置
const columns = () => [
  {
    prop: 'courseName',
    label: '课程名称',
  },
  {
    prop: 'internalTeacherName',
    label: '讲师',
  },
  {
    prop: 'courseType',
    label: '课程类型',
    formatter: row => ['录播', '直播'][row.courseType],
  },
  {
    prop: 'durationTotal',
    label: '总时长（分钟）',
    sortable: 'sortable',
  },
]

const operates = _this => [
  {
    label: '删除',
    method: _this.handleDelete,
  },
]

import TableList from '@/components/TableList'
import { classCertCourse, courseTrainTypeUpdate, courseDelete } from '@/api/certCenter'
// import AddCourseShop from './addCourseShop'
// import AddCoursePackage from './addCoursePackage'
import to from 'await-to'
export default {
  name: 'TrainingAddCourse',
  components: {
    TableList,
    // AddCourseShop,
    // AddCoursePackage,
  },
  props: {
    classCertId: {
      type: [Number, String],
      default: '',
    },
  },
  data() {
    return {
      btns,
      columns,
      operates,
      loading: false,
      dataList: [],
      selectList: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      if (this.loading) return
      const { current, size } = this.pager

      this.loading = true
      const [res, err] = await to(
        classCertCourse({ current, size, certPrivateTrainId: parseInt(this.classCertId) }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.dataList = res.data.list
    },
    // handleAddFromShop() {
    //   this.$refs.addCourseShop.show()
    // },
    handleAddFromPacket() {
      // this.$refs.addCoursePackage.show()
      this.$router.push(`/certCenter/addCourse/${this.classCertId}`)
    },
    addSuccess() {
      this.getList()
    },
    handleDelete(row) {
      this.$confirm('确认删除课程吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const [, err] = await to(courseDelete({ id: row.id }))
        if (err) return this.$message.warning(err.msg)
        this.$message.success('删除成功')
        this.getList()
      })
    },
    async handleTrainTypeChange(v, row) {
      const [, err] = await to(
        courseTrainTypeUpdate({ classCourseId: row.classCourseId, courseTrainType: v }),
      )
      if (err) return this.$message.warning(err.msg)
      row.courseTrainType = v
    },
  },
}
</script>
