import { render, staticRenderFns } from "./addQuestionBank.vue?vue&type=template&id=14a656b1&scoped=true&"
import script from "./addQuestionBank.vue?vue&type=script&lang=js&"
export * from "./addQuestionBank.vue?vue&type=script&lang=js&"
import style0 from "./addQuestionBank.vue?vue&type=style&index=0&id=14a656b1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14a656b1",
  null
  
)

export default component.exports