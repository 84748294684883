<template>
  <div class="cert-traning-manage-add">
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title">
          <div v-if="isFound == 1">创建证书培训</div>
          <div v-else>编辑证书培训</div>
          <el-button style="width:80px;height:25px;margin-left:10px;margin-top: 15px;" @click="back"
            >返回</el-button
          >
        </span>

        <div v-if="form.publishStatus == 0" class="btns">
          <el-button @click="cancel">取消培训</el-button>
          <el-button @click="publish">确认发布</el-button>
        </div>
      </div>
      <div class="card-content">
        <el-tabs v-model="activeTab" tab-position="left">
          <el-tab-pane label="基础信息" name="1">
            <div class="title">
              <span>基本信息</span>
              <el-button type="primary" @click="infoSave">保存</el-button>
            </div>
            <div class="tab-main">
              <el-form ref="form" :model="form" label-width="130px" :rules="rule">
                <el-form-item label="证书培训名称:" prop="certPrivateTrainName">
                  <el-input
                    v-model="form.certPrivateTrainName"
                    placeholder="请输入4~28个字证书培训名称"
                  >
                  </el-input>
                </el-form-item>

                <el-form-item label="适用证书:" prop="productId">
                  <el-select
                    v-model="form.productAttributeCategoryId"
                    placeholder="请选择证书类型"
                    @change="categoryChange"
                  >
                    <el-option
                      v-for="(item, index) in category"
                      :key="index"
                      :value="item.productAttributeCategoryId"
                      :label="item.name"
                    >
                    </el-option>
                  </el-select>

                  <el-select
                    v-model="form.certId"
                    value-key="productId"
                    placeholder="请选择证书"
                    @change="certChange"
                  >
                    <el-option
                      v-for="(item, index) in listReview"
                      :key="index"
                      :value="item.certId"
                      :label="item.certName"
                      :disabled="item.publishStatus == 0"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item
                  v-if="form.productAttributeCategoryId == 3"
                  label="证书等级:"
                  prop="certLevel"
                >
                  <div v-if="levelArrays.length === 0">请选择证书</div>
                  <el-radio-group
                    v-for="(level_item, level_index) in levelArrays"
                    :key="level_index"
                    v-model="form.certLevel"
                  >
                    <el-radio style="margin-right: 20px;" :label="level_item">{{
                      certLevelShowArrays[level_index]
                    }}</el-radio>
                    <!--         <el-radio label="2">二级</el-radio>
                    <el-radio label="3">三级</el-radio>
                    <el-radio label="4">四级</el-radio>
                    <el-radio label="5">五级</el-radio> -->
                  </el-radio-group>
                </el-form-item>

                <el-form-item ref="pic" label="封面图片" prop="pic">
                  <upload-image
                    v-model="form.pic"
                    :width-size="750"
                    :height-size="420"
                    :upload-img="$refs.pic"
                  ></upload-image>
                  <p class="tip">图片尺寸(750 * 420)，大小5MB内，支持扩展名：.png .jpg .jpeg</p>
                </el-form-item>

                <el-form-item label="理论考试地点:" prop="theoryAddress">
                  <el-input v-model="form.theoryAddress" placeholder="请输入理论考试地点">
                  </el-input>
                </el-form-item>

                <el-form-item label="理论考试时间:" prop="theoryTime">
                  <el-date-picker
                    v-model="form.theoryTime"
                    style="height: 40px; width: 350px;"
                    type="datetimerange"
                    range-separator="~"
                    start-placeholder="开始日期"
                    :default-time="['00:00:00', '23:59:59']"
                    :picker-options="pickerBeginOption"
                    end-placeholder="结束日期"
                    size="small"
                    value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </el-form-item>

                <el-form-item label="技能考试地点:" prop="skillAddress">
                  <el-input v-model="form.skillAddress" placeholder="请输入技能考试地点">
                  </el-input>
                </el-form-item>

                <el-form-item label="技能考试时间:" prop="skillTime">
                  <el-date-picker
                    v-model="form.skillTime"
                    style="height: 40px; width: 350px;"
                    type="datetimerange"
                    range-separator="~"
                    start-placeholder="开始日期"
                    :default-time="['00:00:00', '23:59:59']"
                    :picker-options="pickerBeginOption"
                    end-placeholder="结束日期"
                    size="small"
                    value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </el-form-item>

                <el-form-item label="综合考试地点:" prop="synthesizeAddress">
                  <el-input v-model="form.synthesizeAddress" placeholder="请输入综合考试地点">
                  </el-input>
                </el-form-item>

                <el-form-item label="综合考试时间:" prop="synthesizeTime">
                  <el-date-picker
                    v-model="form.synthesizeTime"
                    style="height: 40px; width: 350px;"
                    type="datetimerange"
                    range-separator="~"
                    start-placeholder="开始日期"
                    :default-time="['00:00:00', '23:59:59']"
                    :picker-options="pickerBeginOption"
                    end-placeholder="结束日期"
                    size="small"
                    value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-form>
            </div>
          </el-tab-pane>

          <el-tab-pane label="添加课程" name="3">
            <div class="title">
              <span>添加培训课程</span>
              <!-- <el-button type="primary" @click="infoSave">保存</el-button> -->
            </div>
            <div class="tab-main">
              <AddCourse v-if="activeTab == 3" :class-cert-id="classCertId" />
            </div>
          </el-tab-pane>
          <el-tab-pane label="配置题库" name="4">
            <div class="title">
              <span>配置题库</span>
            </div>
            <div class="tab-main">
              <AddExam v-if="activeTab == 4" :class-cert-id="classCertId" />
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-card>
  </div>
</template>
<script>
import UploadImage from '@/components/UploadImage'
import AddCourse from './components/AddCourse/index'
import AddExam from './components/AddExam/index'
import to from 'await-to'
import { productCertCategory } from '@/api/certCenter'
import {
  classCertBaseInfo,
  classCertBaseEdit,
  classCertPublish,
  classCertDelete,
  certDropDownList,
} from '@/api/certCenter'
export default {
  // name: 'certMangerAdd',
  components: { UploadImage, AddCourse, AddExam },
  data() {
    return {
      pickerBeginOption: {
        disabledDate: time => {
          return (
            time.getTime() <
            new Date().getTime() +
              (new Date(new Date(new Date().toLocaleDateString()).getTime()) - new Date().getTime())
          )
        },
      },

      activeTab: '1',
      // grade:'',
      form: {
        productAttributeCategoryId: '',
        certId: '',
        certLevel: '',
      },
      rule: {
        certPrivateTrainName: [
          { required: true, message: '请输入4~28个字证书培训名称', trigger: 'blur' },
          { min: 4, max: 28, message: '请输入4~28个字证书培训名称', trigger: 'blur' },
        ],
        // productAttributeCategoryId: [{ message: '请选择证书', trigger: 'change' }],
        // grade: [{ message: '请选择证书等级', trigger: 'change' }],

        pic: [{ required: true, message: '请上传商品封面图', trigger: 'blur' }],
        theoryAddress: [
          { min: 4, max: 28, message: '请输入4~128个字理论考试地点', trigger: 'blur' },
        ],
        // endTheoryTime: [{ message: '请选择理论考试时间', trigger: 'change' }],
        skillAddress: [
          { min: 4, max: 28, message: '请输入4~128个字技能考试地点', trigger: 'blur' },
        ],
        // endSkillTime: [{ message: '请选择技能考试时间', trigger: 'change' }],
        synthesizeAddress: [
          { min: 4, max: 28, message: '请输入4~128个字综合考试地点', trigger: 'blur' },
        ],
        // endSynthesizeTime: [{ message: '请选择综合考试时间', trigger: 'change' }],
      },
      category: [],
      listReview: [],
      classCertId: '',
      isFound: 0,
      levelArrays: [],
      certLevelShowArrays: [],
    }
  },
  mounted() {
    const { classCertId, isFound } = this.$route.params
    this.isFound = isFound
    console.log(isFound)
    if (classCertId) {
      this.classCertId = classCertId
      this.getInfo()
    }
    this.getProductCertCategory()
  },
  methods: {
    // async create() {
    //   const [res, err] = await to(classCertCreate({}))
    //   if (err) return this.$message.warning(err.msg)
    //   this.classCertId = res.data
    // },、
    back() {
      this.$router.go(-1)
    },
    async getInfo() {
      const [res, err] = await to(classCertBaseInfo({ certPrivateTrainId: this.classCertId }))
      if (err) return this.$message.warning(err.msg)

      this.form = res.data

      if (res.data.beginTheoryTime) {
        this.form.theoryTime = [res.data.beginTheoryTime, res.data.endTheoryTime]
      }
      if (res.data.beginSkillTime) {
        this.form.skillTime = [res.data.beginSkillTime, res.data.endSkillTime]
      }
      if (res.data.beginSynthesizeTime) {
        this.form.synthesizeTime = [res.data.beginSynthesizeTime, res.data.endSynthesizeTime]
      }

      //转一下类型
      this.form.certLevel = res.data.certLevel + ''

      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })

      if (this.classCertId) {
        this.getListReviewByCategory(this.form.productAttributeCategoryId)
      }
    },
    async getProductCertCategory() {
      const [res, err] = await to(productCertCategory({}))
      if (err) return this.$message.warning(err.msg)
      this.category = res.data
    },

    async getListReviewByCategory(productAttributeCategoryId) {
      const [res, err] = await to(certDropDownList({ productAttributeCategoryId }))
      if (err) return this.$message.warning(err.msg)
      this.listReview = res.data

      if (this.classCertId && productAttributeCategoryId == 3) {
        for (let i = 0; i < this.listReview.length; i++) {
          let item = this.listReview[i]
          if (item.certId == this.form.certId) {
            console.log(item)
            this.levelArrays = item.levelArrays
            this.certLevelShowArrays = item.certLevelShow.split(',')
            console.log(this.certLevelShowArrays)
            console.log(this.levelArrays)
          }
        }
      }
    },
    categoryChange(e) {
      // this.form.productId = ''
      // this.form.productAttributeCategoryId = ''
      this.levelArrays = []
      this.form.certId = ''
      this.form.certLevel = ''
      this.getListReviewByCategory(e)
    },
    certChange(e) {
      console.log(e)
      for (let i = 0; i < this.listReview.length; i++) {
        let item = this.listReview[i]
        if (item.certId == e) {
          console.log(item)
          this.form.certId = item.certId
          this.form.certName = item.certName
          this.form.productAttributeCategoryId = item.productAttributeCategoryId
          this.form.productAttributeCategoryName = item.productAttributeCategoryName

          this.levelArrays = item.levelArrays
          this.certLevelShowArrays = item.certLevelShow.split(',')
          console.log(this.certLevelShowArrays)
          console.log(this.levelArrays)
        }
      }
    },
    infoSave() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          const [, err] = await to(
            classCertBaseEdit({
              ...this.form,
              certPrivateTrainId: this.classCertId,

              beginTheoryTime: this.form.theoryTime ? this.form.theoryTime[0] : null,
              beginSkillTime: this.form.skillTime ? this.form.skillTime[0] : null,
              beginSynthesizeTime: this.form.synthesizeTime ? this.form.synthesizeTime[0] : null,

              endSkillTime: this.form.skillTime ? this.form.skillTime[1] : null,
              endSynthesizeTime: this.form.synthesizeTime ? this.form.synthesizeTime[1] : null,
              endTheoryTime: this.form.theoryTime ? this.form.theoryTime[1] : null,
            }),
          )
          if (err) return this.$message.warning(err.msg)
          this.$message.success('保存成功')
          // this.$router.push('/certCenter/certTrainManager')
        }
      })
    },
    publish() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          const [, err] = await to(
            classCertPublish({
              ...this.form,
              certPrivateTrainId: this.classCertId,

              beginTheoryTime: this.form.theoryTime ? this.form.theoryTime[0] : null,
              beginSkillTime: this.form.skillTime ? this.form.skillTime[0] : null,
              beginSynthesizeTime: this.form.synthesizeTime ? this.form.synthesizeTime[0] : null,

              endSkillTime: this.form.skillTime ? this.form.skillTime[1] : null,
              endSynthesizeTime: this.form.synthesizeTime ? this.form.synthesizeTime[1] : null,
              endTheoryTime: this.form.theoryTime ? this.form.theoryTime[1] : null,
            }),
          )
          if (err) return this.$message.warning(err.msg)
          this.$message.success('发布成功')
          this.$router.push('/certCenter/certTrainManager')
        }
      })

      // const [, err] = await to(classCertPublish({ certPrivateTrainId: this.classCertId }))
      // if (err) return this.$message.warning(err.msg)
      // this.$message.success('发布成功')
      // this.$router.push('/certCenter/certTrainManager')
    },
    async cancel() {
      const [, err] = await to(classCertDelete({ certPrivateTrainId: this.classCertId }))
      if (err) return this.$message.warning(err.msg)
      this.$message.success('取消成功')
      this.$router.push('/certCenter/certTrainManager')
    },
  },
}
</script>
<style lang="scss" scoped>
.cert-traning-manage-add {
  ::v-deep.el-tabs {
    .el-tabs__item {
      width: 180px;
      height: 60px;
      background: #ffffff;
      font-size: 16px;
      font-weight: 400;
      line-height: 60px;
      color: #c9c9c9;
      text-align: center;
      border-right: 2px solid #f5f5f5;
      &.is-active {
        color: #ff7b33;
        background: #fcfbfa;
      }
    }
  }
  ::v-deep.el-card {
    .el-card__body {
      padding: 0;
    }
    .card-content {
      height: calc(100vh - 210px);
      .title {
        height: 78px;
        border-bottom: 1px solid #e2e2e2;
        font-size: 18px;
        font-weight: 500;
        line-height: 78px;
        color: #4d4e5d;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      #pane-1 {
        .el-form {
          .select-list {
            .select-item {
              width: 98px;
              height: 40px;
              line-height: 40px;
              background: #ff7b33;
              border-radius: 4px;
              text-align: center;
              font-size: 14px;
              font-weight: 400;
              color: #ffffff;
            }
          }
          .el-select {
            width: 350px;
            margin-right: 24px;
          }
          .el-input {
            width: 350px;
          }
        }
        .el-form-item__content {
          width: 790px;
          .uploader {
            height: 120px;
            margin-bottom: 12px;
            .el-upload {
              box-sizing: border-box;
              height: 120px;
              width: 160px;
            }
          }
          .tip {
            font-size: 12px;
            line-height: 12px;
            color: #909399;
          }
        }
      }
    }
    .el-card__header {
      .clearfix {
        display: flex;
        align-items: center;
        .btns {
          margin-left: auto;
        }
      }
    }
  }
  ::v-deep.el-tabs {
    height: 100%;
    .el-tabs__header {
      height: 100%;
      padding: 20px;
      border-right: 1px solid #e2e2e2;
      margin-right: 0;
    }
    .el-tabs__nav-wrap::after {
      display: none;
    }
    .tab-main {
      height: calc(100vh - 290px);
      overflow: auto;
      padding: 20px;
      box-sizing: border-box;
    }
  }
  .principal {
    li {
      box-sizing: border-box;
      float: left;
      position: relative;
      cursor: pointer;
      min-width: 80px;
      padding: 0 13px;
      height: 40px;
      opacity: 1;
      background: #ffffff;
      border: 1px solid #d5d5d5;
      border-radius: 5px;
      text-align: center;
      font-size: 14px;
      color: #333333;
      margin-right: 25px;
      margin-bottom: 10px;
      .close {
        position: absolute;
        width: 14px;
        height: 14px;
        right: -5px;
        top: -5px;
      }
    }
  }
}
</style>
