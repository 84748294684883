<template>
  <div class="training-add-course">
    <table-list
      :loading="loading"
      :data="dataList"
      :columns="columns(this)"
      :btns="btns(this)"
      :operates="operates(this)"
      :options="{ operatesWidth: 170 }"
    ></table-list>
    <AddQuestionBank ref="addCourseShop" :class-cert-id="classCertId" @success="addSuccess" />
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  {
    label: '从题库中选择',
    method: _this.handleAdd,
    width: 120,
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'paperName',
    label: '题库名称',
  },
  {
    prop: 'questionTypeMuster',
    label: '试题包含类型',
  },
  {
    prop: 'questionNum',
    label: '题目数量',
    sortable: 'sortable',
  },
]

//操作
const operates = _this => [
  {
    label: '删除',
    type: 'danger',
    method: _this.handleDelete,
  },
]
import TableList from '@/components/TableList'
import { certQuestionsPageList, certQuestionsDelete } from '@/api/certCenter'
import AddQuestionBank from './addQuestionBank'
import to from 'await-to'
export default {
  name: 'TrainingAddCourse',
  components: {
    TableList,
    AddQuestionBank,
  },
  props: {
    classCertId: {
      type: [Number, String],
      default: '',
    },
  },
  data() {
    return {
      btns,
      columns,
      operates,
      loading: false,
      dataList: [],
      selectList: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(
        certQuestionsPageList({ current, size, certPrivateTrainId: parseInt(this.classCertId) }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.dataList = res.data.list
    },
    handleAdd() {
      this.$refs.addCourseShop.show()
    },
    addSuccess() {
      this.getList()
    },
    handleDelete(row) {
      this.$confirm('确认删除课程吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const [, err] = await to(certQuestionsDelete({ id: row.id }))
        if (err) return this.$message.warning(err.msg)
        this.$message.success('删除成功')
        this.getList()
      })
    },
  },
}
</script>
